import * as React from 'react'
import Layout from '../../../templates/layout'
import SloganPage from '../../../components/molecules/SloganPage'
import Seo from '../../../helpers/seo'
import HeaderContentSolution from '../../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../../components/organisms/ContentSolution'
import TabsInformation from '../../../components/molecules/TabsInformation'
import squadsJson from './squads.json'
import ContactForm from '../../../components/organisms/ContactForm'
import CasesRelational from '../../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../../helpers/assignIDToSolutionsJson'

const ServicesOutsourcing = () => {
  const { squadsEstendidas, squadsExclusivas, alocation, outsourcing, relational } =
    assignIDToSolutionsJson(squadsJson)

  const tabContent = [
    {
      id: 1,
      heading: 'Squads Estendidas',
      content: () => {
        return squadsEstendidas.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'squads',
      selected: 'close',
    },
    {
      id: 2,
      heading: 'Squads Exclusivas',
      content: () => {
        return squadsExclusivas.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'squads',
      selected: 'close',
    },
    {
      id: 3,
      heading: 'Alocação',
      content: () => {
        return alocation.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'alocation',
      selected: 'close',
    },
    {
      id: 4,
      heading: 'Outsourcing',
      content: () => {
        return outsourcing.map((data) => {
          return (
            <ContentSolution
              icon={data.icon}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'outsourcing',
      selected: 'close',
    },
  ]

  return (
    <Layout page="ServicesOutsourcing">
      <SloganPage title={'Outsourcing e Alocação'} type={'squads'} />
      <HeaderContentSolution
        icon={'squads'}
        description={`<p>Em um ambiente empresarial dinâmico, a flexibilidade, a competência e rapidez são essenciais. 
        Nosso serviço de Outsourcing e Alocação de desenvolvedores proporciona isso, oferecendo Squads Estendidas, Squads Exclusivas, 
        e profissionais qualificados para impulsionar seus projetos. Com essas soluções, você pode expandir sua equipe com eficiência e 
        alinhamento, garantindo um alto nível de desempenho, qualidade e integração cultural.</p>`}
      />
      <TabsInformation tabColor="orange" tabContent={tabContent} />
      <ContactForm />
      <CasesRelational
        relationalData={relational}
        currentCategory="Outsourcing e Alocação"
      />
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default ServicesOutsourcing
